import { Box, Typography } from "@mui/material";
import React from "react";

const IntroComponent = ({ containerHeight, enlarged }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        component="img"
        src={
          enlarged
            ? "/images/talented/people02.jpg"
            : "/images/talented/people.jpg"
        }
        sx={{
          width: "100%",
          height: "300px",
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Typography
          variant={enlarged ? "h3" : "h4"}
          sx={{
            fontFamily: "GmarketSans-Medium",
            color: "white",
            textShadow:
              "2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          채용안내
        </Typography>
      </Box>
    </Box>
  );
};

export default IntroComponent;
