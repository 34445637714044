import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const JY = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sx={{ backgroundColor: "#d3d8de" }}>
          <Box sx={{ position: "relative" }}>
            <Grid container sx={{ mt: "50px" }}>
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  textAlign: "left",
                }}
              >
                <Box
                  component="img"
                  src="/images/company/whowe02.jpg"
                  sx={{
                    width: enlarged ? "350px" : "150px",
                    height: "auto",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5} sx={{ m: "1em 0", padding: "5% 5%" }}>
                <Typography
                  variant={enlarged ? "h4" : "h6"}
                  sx={{
                    fontFamily: "NotoSansKR-Bold",
                    color: "#bb4695",
                    wordBreak: "keep-all",
                  }}
                >
                  “이해와 신뢰를 바탕으로 고객과 파트너쉽을 쌓아가는 토종 IT
                  전문 회사로 키워 나가겠습니다.“
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",
                top: enlarged ? "150px" : "80px",
                left: enlarged ? "32%" : "40%",
                backgroundColor: "transparent",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "NotoSansKR-Bold", mb: "1em" }}
              >
                전진영 공동대표
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#757575",
                  mb: enlarged ? "0.3em" : 0,
                }}
              >
                라이나금융서비스 IT부문 대표
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#757575",
                  mb: enlarged ? "0.3em" : 0,
                }}
              >
                라이나생명 IT운영 임원
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#757575",
                  mb: enlarged ? "0.3em" : 0,
                }}
              >
                IBM GTS 아키텍트팀 팀장
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#757575",
                  mb: enlarged ? "0.3em" : 0,
                }}
              >
                PM, 인프라, 재해복구 전문가
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default JY;
