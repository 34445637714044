import { Box, Grid } from "@mui/material";
import React from "react";
import { useOutletContext } from "react-router-dom";
import IntroComponent from "../../components/contact/IntroComponent";
import ContactComponent from "../../components/contact/ContactComponent";
import Bottom from "../Bottom";
import LocationComponent from "../../components/contact/LocationComponent";
import ScrollToAnchor from "../../components/misc/ScrollToAnchor";
import SpeedDialComponent from "../../components/misc/SpeedDialComponent";

const Contact = () => {
  const containerHeight = `${window.innerHeight}px`;
  const [enlarged, scrolled] = useOutletContext();

  return (
    <Box id="top">
      <Grid container>
        <Grid item xs={12}>
          <IntroComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12} id="contact">
          <ContactComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12} id="map">
          <LocationComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12}>
          <Bottom containerHeight={containerHeight} enlarged={enlarged} />
        </Grid>
      </Grid>
      <ScrollToAnchor />
      {!enlarged && scrolled && <SpeedDialComponent />}
    </Box>
  );
};

export default Contact;
