import { Box, Grid } from "@mui/material";
import React from "react";
import IntroComponent from "../../components/business/IntroComponent";
import Bottom from "../Bottom";
import { useOutletContext } from "react-router-dom";
import ScrollToAnchor from "../../components/misc/ScrollToAnchor";
import ConsultingComponent from "../../components/business/ConsultingComponent";
import PortfolioComponent from "../../components/business/PortfolioComponent";
import InfraComponent from "../../components/business/InfraComponent";
import SolutionsComponent from "../../components/business/SolutionsComponent";
import SpeedDialComponent from "../../components/misc/SpeedDialComponent";

const Business = () => {
  const containerHeight = `${window.innerHeight}px`;
  const [enlarged, scrolled] = useOutletContext();

  return (
    <Box id="top">
      <Grid container>
        <Grid item xs={12}>
          <IntroComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12} id="portfolio">
          <PortfolioComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>

        <Grid item xs={12} id="consulting">
          <ConsultingComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>

        <Grid item xs={12} id="infra">
          <InfraComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12} id="solution">
          <SolutionsComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12}>
          <Bottom />
        </Grid>
      </Grid>
      <ScrollToAnchor />
      {!enlarged && scrolled && <SpeedDialComponent />}
    </Box>
  );
};

export default Business;
