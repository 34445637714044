import React from "react";
import MembersComponent from "../../components/company/MembersComponent";
import { useOutletContext } from "react-router-dom";

const MembersOnly = () => {
  const containerHeight = `${window.innerHeight}px`;
  const [enlarged, scrolled] = useOutletContext();
  return (
    <MembersComponent
      containerHeight={containerHeight}
      enlarged={enlarged}
      scrolled={scrolled}
      direct={true}
    />
  );
};

export default MembersOnly;
