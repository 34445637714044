import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const YJ = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sx={{ backgroundColor: "#d3d8de" }}>
          <Box sx={{ position: "relative" }}>
            <Grid container sx={{ mt: "50px" }}>
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  textAlign: "left",
                }}
              >
                <Box
                  component="img"
                  src="/images/company/whowe04.jpg"
                  sx={{
                    width: enlarged ? "350px" : "150px",
                    height: "auto",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5} sx={{ padding: "5% 5%" }}>
                <Typography
                  variant={enlarged ? "h4" : "h6"}
                  sx={{
                    fontFamily: "NotoSansKR-Bold",
                    color: "#bb4695",
                    wordBreak: "keep-all",
                  }}
                >
                  “고객의 성공을 위해 함께 노력하고 즐겁게 일하는 회사를
                  만들겠습니다.“
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",
                top: enlarged ? "150px" : "80px",
                left: enlarged ? "32%" : "40%",
                backgroundColor: "transparent",
                textAlign: "left",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "NotoSansKR-Bold", mb: "1em" }}
              >
                김영준 차장
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#757575",
                  mb: enlarged ? "0.3em" : 0,
                }}
              >
                인프라, 클라우드 전문가
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#757575",
                  mb: enlarged ? "0.3em" : 0,
                }}
              >
                인프라스트럭처 아키텍트
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default YJ;
