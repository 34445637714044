import React from "react";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { purple } from "@mui/material/colors";

const AzulComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Grid container sx={{ padding: "1em" }}>
        <Grid item xs={12} md={5}>
          <Box sx={{ mb: "1em" }}>
            <Box sx={{ mb: "2em" }}>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "NotoSansKR-Bold",
                  color: "#9534c1",
                  mb: "0.5em",
                }}
              >
                Azul Systems
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontFamily: "NotoSansKR-Medium", color: "#414141" }}
              >
                고성능 JAVA 가상 머신
              </Typography>
              <Box sx={{ textAlign: "center" }}>
                <Box
                  component="img"
                  src="/images/business/solution02.png"
                  sx={{ height: "90px", width: "auto" }}
                />
              </Box>
            </Box>
            {[
              "대다수의 글로벌 Company가 사용합니다.",
              "동일 인프라에서 최대의 성능을 뽑아 냅니다. ",
              "클라우드 비용을 줄입니다.",
              "보안 위협에 안전한 인프라를 제공합니다.",
            ].map((item, idx) => (
              <Stack key={idx} direction="row">
                <CheckRoundedIcon sx={{ color: "#414141", mr: "0.3em" }} />
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "NotoSansKR-Medium",
                    color: "#414141",
                    wordBreak: "keep-all",
                  }}
                >
                  {item}
                </Typography>
              </Stack>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} md={7}>
          {/* Core 시작 */}
          <Box sx={{ mb: "2em", mt: "1em" }}>
            <Box sx={{ borderBottom: "1px solid #212121", mb: "0.5em" }}>
              <Stack direction="row" sx={{ mb: "0.5em" }}>
                <ContentPasteRoundedIcon
                  sx={{ color: purple[800], mr: "0.5em" }}
                />
                <Typography
                  varaint="h5"
                  sx={{ fontFamily: "NotoSansKR-Medium", color: purple[800] }}
                >
                  Azul Platform Core
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={5}>
                  <Box sx={{ textAlign: "center" }}>
                    <Box
                      component="img"
                      src="/images/business/sol01.png"
                      sx={{ width: "100px", height: "auto" }}
                    />
                    <Typography variant="body2">100% 호환 JDK</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box>
                    {[
                      "100% 호환 JDK",
                      "OpenJDK 호환성",
                      "더 좋은 기술지원 정책",
                      "Oracle대비 70% 저렴",
                    ].map((item, idx) => (
                      <Stack key={idx} direction="row">
                        <ArrowRightRoundedIcon
                          size="small"
                          sx={{ color: "#212121" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "#212121", wordBreak: "keep-all" }}
                        >
                          {item}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* Core 끝 */}

          {/* Prime 시작 */}
          <Box sx={{ mb: "2em" }}>
            <Box sx={{ borderBottom: "1px solid #212121", mb: "0.5em" }}>
              <Stack direction="row" sx={{ mb: "0.5em" }}>
                <ContentPasteRoundedIcon
                  sx={{ color: purple[800], mr: "0.5em" }}
                />
                <Typography
                  varaint="h5"
                  sx={{ fontFamily: "NotoSansKR-Medium", color: purple[800] }}
                >
                  Azul Platform Prime
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={5}>
                  <Box sx={{ textAlign: "center" }}>
                    <Box
                      component="img"
                      src="/images/business/sol02.png"
                      sx={{ width: "100px", height: "auto" }}
                    />
                    <Typography variant="body2">고성능 JVM</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box>
                    {[
                      "서비스 품질 향상",
                      "놀라운 성능 개선",
                      "50% 인프라 비용 절감",
                      "즉각적인 시스템 안정성 효과",
                    ].map((item, idx) => (
                      <Stack key={idx} direction="row">
                        <ArrowRightRoundedIcon
                          size="small"
                          sx={{ color: "#212121", mr: "0.5em" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "#212121", wordBreak: "keep-all" }}
                        >
                          {item}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* Prime 끝 */}

          {/* Vulnerability Detection 시작 */}
          <Box sx={{ mb: "2em" }}>
            <Box sx={{ borderBottom: "1px solid #212121", mb: "0.5em" }}>
              <Stack direction="row" sx={{ mb: "0.5em" }}>
                <ContentPasteRoundedIcon
                  sx={{ color: purple[800], mr: "0.5em" }}
                />
                <Typography
                  varaint="h5"
                  sx={{ fontFamily: "NotoSansKR-Medium", color: purple[800] }}
                >
                  Vulnerability Detection
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={5}>
                  <Box sx={{ textAlign: "center" }}>
                    <Box
                      component="img"
                      src="/images/business/sol03.png"
                      sx={{ width: "100px", height: "auto" }}
                    />
                    <Typography variant="body2">
                      Java 보안취약성 대응
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box>
                    {[
                      "Azul JVM 기반 보안취약성 지속 감지",
                      "JVM 로딩 코드 모니터링",
                      "운영/사용환경 내 보안취약점 대응",
                      "No code scan, No agent, No 성능저하",
                    ].map((item, idx) => (
                      <Stack key={idx} direction="row">
                        <ArrowRightRoundedIcon
                          size="small"
                          sx={{ color: "#212121", mr: "0.5em" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "#212121", wordBreak: "keep-all" }}
                        >
                          {item}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* Vulnerability Detection 끝 */}
        </Grid>
      </Grid>
    </div>
  );
};

export default AzulComponent;
