import { Box, Typography } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";

const IntroComponent = ({ containerHeight, enlarged }) => {
  const door = [
    {
      wide: "/images/home/design_wide.jpg",
      narr: "/images/home/design_narr.jpg",
      slogan: "We design more than what you expect",
      desc: "풍부한 경험과 IT 전문 지식을 바탕으로 견고하고 안정적인 시스템을 설계합니다.",
    },
    {
      wide: "/images/home/quality_wide.jpg",
      narr: "/images/home/quality_narr.jpg",
      slogan: "We privide the best quality we can",
      desc: "우리의 모든 역량을 다하여 항상 최고 품질의 IT시스템을 구축합니다.",
    },
    {
      wide: "/images/home/door3_wide.jpg",
      narr: "/images/home/door3_narr.jpg",
      slogan: "We make optimum execellent products",
      desc: "고객의 요구에 꼭 맞는 최적화된 솔루션을 제공합니다.",
    },
    {
      wide: "/images/home/drive2_wide.jpg",
      narr: "/images/home/drive2_narr.jpg",
      slogan: "We drive your business to spring",
      desc: "고객 비즈니스 경쟁력 향상을 위한 고품질의 소프트웨어와 IT기술을 제공합니다.",
    },
  ];

  return (
    <div>
      <Box>
        <Swiper loop={true} pagination={true} modules={[Pagination]}>
          {door.map((item, idx) => (
            <SwiperSlide key={idx}>
              <Box
                sx={{
                  backgroundImage: enlarged
                    ? `url(${item.wide})`
                    : `url(${item.narr})`,
                  backgroundSize: "cover",
                  height: containerHeight,
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <Box
                  sx={{
                    m: enlarged ? "5em 5em" : "3em 1em",
                  }}
                >
                  <Typography
                    variant={enlarged ? "h3" : "h4"}
                    sx={{
                      fontFamily: "CantoraOne-Regular",
                      color: "#fff",
                      wordBreak: "kee-all",
                      mb: "0.5em",
                    }}
                  >
                    {item.slogan}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#fff", wordBreak: "keep-all" }}
                  >
                    {item.desc}
                  </Typography>
                </Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </div>
  );
};

export default IntroComponent;
