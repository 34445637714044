import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";

import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";

const CustomersComponent = ({ containerHeight, enlarged }) => {
  const clients = [
    { image: "/images/company/client01.png", name: "라이나생명" },
    { image: "/images/company/client02.png", name: "한국해양진흥공사" },
    { image: "/images/company/client03.png", name: "라이나원" },
    { image: "/images/company/client04.png", name: "현대해상" },
    { image: "/images/company/client05.png", name: "하나은행" },
    { image: "/images/company/client06.png", name: "농협생명" },
  ];

  const partners = [
    { image: "/images/company/partner01.png", name: "에스케이주식회사" },
    { image: "/images/company/partner02.png", name: "동양시스템즈" },
    { image: "/images/company/partner03.png", name: "아이티아이즈" },
    { image: "/images/company/partner04.png", name: "AZUL" },
    { image: "/images/company/partner05.png", name: "IBM" },
  ];

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#f6f6f6",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12}>
            <Box
              sx={{
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" sx={{ fontFamily: "NotoSansKR-Bold" }}>
                고객 및 파트너쉽
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ paddingLeft: "2em", mt: "1em" }}>
              <Stack direction="row" sx={{ alignItems: "center" }}>
                <ArrowRightRoundedIcon size="large" />
                <Typography
                  variant={enlarged ? "h5" : "h6"}
                  sx={{ fontFamily: "NotoSansKR-Bold" }}
                >
                  주요 고객
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ padding: "1em 0.5em", width: "90%" }}>
              <Grid container columns={{ xs: 4, md: 10 }} spacing={2}>
                {clients.map((client, idx) => (
                  <Grid key={idx} item xs={2}>
                    <Paper sx={{ textAlign: "center", padding: "0.5em" }}>
                      <Box
                        component="img"
                        src={client.image}
                        sx={{ width: "80%", height: "auto" }}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ paddingLeft: "2em", mt: "1em" }}>
              <Stack direction="row" sx={{ alignItems: "center" }}>
                <ArrowRightRoundedIcon size="large" />
                <Typography
                  variant={enlarged ? "h5" : "h6"}
                  sx={{ fontFamily: "NotoSansKR-Bold" }}
                >
                  주요 파트너
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ padding: "1em 0.5em", mb: "3em", width: "90%" }}>
              <Grid container columns={{ xs: 4, md: 10 }} spacing={2}>
                {partners.map((client, idx) => (
                  <Grid key={idx} item xs={2}>
                    <Paper sx={{ textAlign: "center", padding: "0.5em" }}>
                      <Box
                        component="img"
                        src={client.image}
                        sx={{ width: "80%", height: "auto" }}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CustomersComponent;
