import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

const DigitalInnoComponent = ({ enlarged }) => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundImage: "url(/images/main02.jpg)",
              backgroundSize: "cover",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                top: "10%",
                width: "90%",
                textAlign: "center",
                padding: "2em 1em",
              }}
            >
              <Typography
                variant={enlarged ? "h4" : "h5"}
                sx={{ fontFamily: "NotoSansKR-Bold", color: "white" }}
              >
                고객의 디지털 비즈니스 혁신,
                <br /> 진달래소프트가 돕습니다.
              </Typography>
              <Typography
                varaint="h6"
                sx={{
                  fontFamily: "NotoSAnsKR-Medium",
                  color: "#e0e0e0",
                  mt: "1.5em",
                  wordBreak: "keep-all",
                }}
              >
                다양한 오픈소스 활용 기술과 고품질 프레임워크를 활용하여 디지털
                비즈니스 혁신을 이루세요.
              </Typography>
            </Box>
            <Box
              sx={{
                width: enlarged ? "60%" : "85%",
                textAlign: "center",
                mb: "2em",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={4}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card sx={{ width: "100%", height: "120px" }}>
                    <CardContent>
                      <Box
                        component="img"
                        src="/images/archi.png"
                        sx={{ width: "48px", height: "48px" }}
                      />
                    </CardContent>
                    <CardHeader
                      title="아키텍처"
                      sx={{ padding: 0, minHeight: "20px" }}
                      titleTypographyProps={{
                        variant: "body2",
                        fontFamily: "NotoSansKR-Medium",
                      }}
                    />
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card sx={{ width: "100%", height: "120px" }}>
                    <CardContent>
                      <Box
                        component="img"
                        src="/images/cloud.png"
                        sx={{ width: "48px", height: "48px" }}
                      />
                    </CardContent>
                    <CardHeader
                      title="클라우드"
                      sx={{ padding: 0, minHeight: "20px" }}
                      titleTypographyProps={{
                        variant: "body2",
                        fontFamily: "NotoSansKR-Medium",
                      }}
                    />
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card sx={{ width: "100%", height: "120px" }}>
                    <CardContent>
                      <Box
                        component="img"
                        src="/images/framework.png"
                        sx={{ width: "48px", height: "48px" }}
                      />
                    </CardContent>
                    <CardHeader
                      title="프레임워크"
                      sx={{ padding: 0, minHeight: "20px" }}
                      titleTypographyProps={{
                        variant: "body2",
                        fontFamily: "NotoSansKR-Medium",
                      }}
                    />
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card sx={{ width: "100%", height: "120px" }}>
                    <CardContent>
                      <Box
                        component="img"
                        src="/images/oss.png"
                        sx={{ width: "48px", height: "48px" }}
                      />
                    </CardContent>
                    <CardHeader
                      title="오픈소스"
                      sx={{ padding: 0, minHeight: "20px" }}
                      titleTypographyProps={{
                        variant: "body2",
                        fontFamily: "NotoSansKR-Medium",
                      }}
                    />
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card sx={{ width: "100%", height: "120px" }}>
                    <CardContent>
                      <Box
                        component="img"
                        src="/images/automation.png"
                        sx={{ width: "48px", height: "48px" }}
                      />
                    </CardContent>
                    <CardHeader
                      title="IT자동화"
                      sx={{ padding: 0, minHeight: "20px" }}
                      titleTypographyProps={{
                        variant: "body2",
                        fontFamily: "NotoSansKR-Medium",
                      }}
                    />
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card sx={{ width: "100%", height: "120px" }}>
                    <CardContent>
                      <Box
                        component="img"
                        src="/images/cicd.png"
                        sx={{ width: "48px", height: "48px" }}
                      />
                    </CardContent>
                    <CardHeader
                      title="CI/CD"
                      sx={{ padding: 0, minHeight: "20px" }}
                      titleTypographyProps={{
                        variant: "body2",
                        fontFamily: "NotoSansKR-Medium",
                      }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default DigitalInnoComponent;
