import { SpeedDial, SpeedDialAction, Typography } from "@mui/material";
import React from "react";

import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import { menus } from "../../pages/MenuBar";

import VerticalAlignTopRoundedIcon from "@mui/icons-material/VerticalAlignTopRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

import { useNavigate } from "react-router-dom";
import { blueGrey } from "@mui/material/colors";

const SpeedDialComponent = () => {
  const navigate = useNavigate();
  const width = "100px";

  const goto = (url) => {
    navigate(url);
  };

  return (
    <SpeedDial
      ariaLabel="Speed Dial"
      sx={{ position: "fixed", bottom: 16, left: 16 }}
      FabProps={{ sx: { bgcolor: blueGrey[400] } }}
      direction="up"
      icon={<TouchAppRoundedIcon sx={{ color: blueGrey[100] }} />}
    >
      {menus &&
        menus
          .slice()
          .reverse()
          .map((item, idx) => (
            <SpeedDialAction
              key={idx}
              icon={
                <>
                  {item.icon}
                  <Typography variant="body2" sx={{ ml: "0.5em" }}>
                    {item.title}
                  </Typography>
                </>
              }
              FabProps={{
                sx: {
                  borderRadius: 2,
                  width: width,
                  backgroundColor: "#FFF",
                },
              }}
              onClick={() => goto(item.link)}
            />
          ))}
      <SpeedDialAction
        icon={
          <>
            <HomeRoundedIcon />
            <Typography variant="body2" sx={{ ml: "0.5em" }}>
              Home
            </Typography>
          </>
        }
        onClick={() => goto("/#top")}
        FabProps={{
          sx: {
            borderRadius: 2,
            width: width,
            backgroundColor: "#FFF",
          },
        }}
        TooltipClasses={{ tooltip: { fontSize: 50 } }}
      />
      <SpeedDialAction
        icon={
          <>
            <VerticalAlignTopRoundedIcon />
            <Typography variant="body2" sx={{ ml: "0.5em" }}>
              맨 위로
            </Typography>
          </>
        }
        onClick={() => goto("#top")}
        FabProps={{
          sx: {
            borderRadius: 2,
            width: width,
            backgroundColor: "#FFF",
          },
        }}
        TooltipClasses={{ tooltip: { fontSize: 50 } }}
      />
    </SpeedDial>
  );
};

export default SpeedDialComponent;
