import { Box, Typography } from "@mui/material";
import React from "react";

const IntroComponent = ({ containerHeight, enlarged }) => {
  return (
    <Box sx={{ height: containerHeight, position: "relative" }}>
      <Box
        component="img"
        src="/images/business/business.jpg"
        sx={{
          width: "100%",
          height: containerHeight,
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          ml: enlarged ? "4em" : "1em",
          position: "absolute",
          top: "75%",
        }}
      >
        <Typography
          variant={enlarged ? "h3" : "h5"}
          sx={{
            fontFamily: "GmarketSans-Medium",
            color: "white",
            wordBreak: "keep-all",
            textShadow:
              "2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          믿을 수 있는 전문가 서비스와 <br />
          문제를 해결하는 솔루션
        </Typography>
      </Box>
    </Box>
  );
};

export default IntroComponent;
