import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { purple } from "@mui/material/colors";

const ConsultingComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#efefef",
        }}
      >
        <Grid container sx={{ m: "2em 0", width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12}>
            <Box sx={{ p: "2em 0", textAlign: "center" }}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "NotoSansKR-Bold", color: "#212121" }}
              >
                IT 컨설팅
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box>
              <Box
                component="img"
                src="/images/company/history.jpg"
                sx={{
                  width: "100%",
                  height: enlarged ? "500px" : "300px",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ backgroundColor: "#efefef" }}>
            <Box sx={{ padding: "1em 1em" }}>
              <Box sx={{ textAlign: "center", mb: "1em", padding: "1em 0" }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: "1em",
                    color: "#414141",
                    fontFamily: "NotoSansKR-Bold",
                    wordBreak: "keep-all",
                  }}
                >
                  고객 비즈니스에 최적화된
                  <br /> IT시스템 구축 컨설팅을 제공합니다.
                </Typography>
                <Box component="img" src="/images/business/system01.png" />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box>
                  <Stack
                    direction="row"
                    sx={{ alignItems: "center", mb: "0.5em" }}
                  >
                    <EventNoteRoundedIcon
                      sx={{ color: purple[800], mr: "0.3em" }}
                    />
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: "NotoSansKR-Bold", color: purple[800] }}
                    >
                      IT 컨설팅 세부 영역
                    </Typography>
                  </Stack>
                  {[
                    "정보 전략 수립",
                    "시스템 및 응용프로그램 아키텍처 설계",
                    "클라우드 전환 전략 수립",
                    "시스템 진단 및 개선 방안 도출",
                  ].map((item, idx) => (
                    <Stack key={idx} direction="row">
                      <KeyboardArrowRightRoundedIcon
                        sx={{ color: "#414141", m: "0 0.5em" }}
                      />
                      <Typography
                        variant="body1"
                        sx={{ color: "#414141", wordBreak: "keep-all" }}
                      >
                        {item}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ConsultingComponent;
