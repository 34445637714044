import { Box, Typography } from "@mui/material";
import React from "react";

const IntroComponent = ({ containerHeight, enlarged }) => {
  return (
    <Box sx={{ height: containerHeight, position: "relative" }}>
      <Box
        component="img"
        src={
          enlarged
            ? "/images/company/company_wide.jpg"
            : "/images/company/company_narr.jpg"
        }
        sx={{
          width: "100%",
          height: containerHeight,
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          ml: enlarged ? "4em" : "1em",
          position: "absolute",
          top: "75%",
        }}
      >
        <Typography
          variant={enlarged ? "h3" : "h5"}
          sx={{
            fontFamily: "GmarketSans-Medium",
            color: "white",
            textShadow:
              "2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          진달래소프트와 함께라면 <br />
          언제나 봄 입니다.
        </Typography>
      </Box>
    </Box>
  );
};

export default IntroComponent;
