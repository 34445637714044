import { Box, Grid } from "@mui/material";
import React from "react";
import { useOutletContext } from "react-router-dom";
import Bottom from "../Bottom";
import IntroComponent from "../../components/company/IntroComponent";
import MissionComponent from "../../components/company/MissionComponent";
import HistoryComponent from "../../components/company/HistoryComponent";
import MembersComponent from "../../components/company/MembersComponent";
import CustomersComponent from "../../components/company/CustomersComponent";
import CIComponent from "../../components/company/CIComponent";
import ScrollToAnchor from "../../components/misc/ScrollToAnchor";
import SpeedDialComponent from "../../components/misc/SpeedDialComponent";

const Company = () => {
  const containerHeight = `${window.innerHeight}px`;
  const [enlarged, scrolled] = useOutletContext();

  return (
    <Box id="top">
      <Grid container>
        <Grid item xs={12}>
          <IntroComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12} id="mission">
          <MissionComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12} id="history">
          <HistoryComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12} id="members">
          <MembersComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12} id="clients">
          <CustomersComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12} id="ci">
          <CIComponent containerHeight={containerHeight} enlarged={enlarged} />
        </Grid>
        <Grid item xs={12}>
          <Bottom />
        </Grid>
      </Grid>
      <ScrollToAnchor />
      {!enlarged && scrolled && <SpeedDialComponent />}
    </Box>
  );
};

export default Company;
