import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import AppspineComponent from "./AppspineComponent";
import AzulComponent from "./AzulComponent";

const SolutionsComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center", m: "2em 0" }}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "NotoSansKR-Bold", color: "#212121" }}
              >
                솔루션 공급
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <AppspineComponent
              containerHeight={containerHeight}
              enlarged={enlarged}
            />
          </Grid>
          <Grid item xs={12}>
            <AzulComponent
              containerHeight={containerHeight}
              enlarged={enlarged}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SolutionsComponent;
