import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { purple } from "@mui/material/colors";

const AppspineComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={5} sx={{ padding: "1em" }}>
          <Box>
            <Box sx={{ mb: "2em" }}>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "NotoSansKR-Bold",
                  color: "#c00000",
                  mb: "0.5em",
                }}
              >
                APPSPINE
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#414141",
                  wordBreak: "keep-all",
                }}
              >
                MSA, Cloud 환경에 최적화된 J2EE 프레임워크
              </Typography>
              <Box sx={{ textAlign: "center" }}>
                <Box component="img" src="/images/business/appspine.png" />
              </Box>
            </Box>
            {[
              "원하는 시스템을 빠르게 개발합니다.",
              "검증된 오픈소스를 쉽게 이용합니다.",
              "안정적이고 견고한 아키텍처를 제공합니다.",
            ].map((item, idx) => (
              <Stack key={idx} direction="row">
                <CheckRoundedIcon sx={{ color: "#414141", mr: "0.5em" }} />
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "NotoSansKR-Medium",
                    color: "#414141",
                    wordBreak: "keep-all",
                  }}
                >
                  {item}
                </Typography>
              </Stack>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={7} sx={{ padding: "1em" }}>
          {/* 구성도 시작 */}
          <Box sx={{ mb: "1em" }}>
            <Box sx={{ borderBottom: "1px solid #212121", mb: "0.5em" }}>
              <Stack direction="row" sx={{ mb: "0.5em" }}>
                <ContentPasteRoundedIcon
                  sx={{ color: purple[800], mr: "0.5em" }}
                />
                <Typography
                  varaint="h5"
                  sx={{ fontFamily: "NotoSansKR-Medium", color: purple[800] }}
                >
                  구성도
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Box
                component="img"
                src="/images/business/appspine_module.png"
                sx={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Box>
          {/* 구성도 끝 */}
          {/* 특징 시작 */}
          <Box sx={{ m: "2em 0" }}>
            <Box sx={{ borderBottom: "1px solid #212121", mb: "0.5em" }}>
              <Stack direction="row" sx={{ mb: "0.5em" }}>
                <ContentPasteRoundedIcon
                  sx={{ color: purple[800], mr: "0.5em" }}
                />
                <Typography
                  varaint="h5"
                  sx={{ fontFamily: "NotoSansKR-Medium", color: purple[800] }}
                >
                  특징
                </Typography>
              </Stack>
            </Box>
            <Box>
              {[
                "SpringBoot기반 MSA에 최적화된 프레임워크",
                "엔터프라이즈급 지원 기능이 보강된 오픈소스WAS를 내장하여 별도 상용WAS 구매 불필요",
                "권한, 인증, 데이터암호화 등 고급 시큐리티 지원 기능 제공",
                "개발 생산성 향상을 위한 다양한 Built-In 라이브러리 제공",
                "데이터 관리 효율화를 위한 IMDG 내장",
              ].map((item, idx) => (
                <Stack key={idx} direction="row">
                  <ArrowRightRoundedIcon
                    size="small"
                    sx={{ color: "#212121", mr: "0.5em" }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ color: "#212121", wordBreak: "keep-all" }}
                  >
                    {item}
                  </Typography>
                </Stack>
              ))}
            </Box>
          </Box>
          {/* 특징 끝 */}
          {/* 스펙 시작 */}
          <Box sx={{ m: "2em 0" }}>
            <Box sx={{ borderBottom: "1px solid #212121", mb: "0.5em" }}>
              <Stack direction="row" sx={{ mb: "0.5em" }}>
                <ContentPasteRoundedIcon
                  sx={{ color: purple[800], mr: "0.5em" }}
                />
                <Typography
                  varaint="h5"
                  sx={{ fontFamily: "NotoSansKR-Medium", color: purple[800] }}
                >
                  스펙
                </Typography>
              </Stack>
            </Box>
            <Box>
              {[
                "Spring Ecosystem의 최신 라이브러리 지원",
                "Java 8 이상이 탑재된 모든 OS 지원",
                "JDBC라이브러리를 제공하는 모든 RDBMS 지원",
                "Redis Cluster 제공",
              ].map((item, idx) => (
                <Stack key={idx} direction="row">
                  <ArrowRightRoundedIcon
                    size="small"
                    sx={{
                      color: "#212121",
                      mr: "0.5em",
                      wordBreak: "keep-all",
                    }}
                  />
                  <Typography variant="body2" sx={{ color: "#212121" }}>
                    {item}
                  </Typography>
                </Stack>
              ))}
            </Box>
          </Box>
          {/* 스펙 끝 */}
        </Grid>
      </Grid>
    </div>
  );
};

export default AppspineComponent;
