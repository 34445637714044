import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";

import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import MailRoundedIcon from "@mui/icons-material/MailRounded";

const ProcessComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          sx={{ padding: "2em 1em", width: enlarged ? "80%" : "100%" }}
        >
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center", m: "1em 0 2em 0" }}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "NotoSansKR-Bold", color: "#21211" }}
              >
                채용절차
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    backgroundColor: "#bb4695",
                    height: enlarged ? "80px" : "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #bb4695",
                  }}
                >
                  <Typography
                    sx={{ fontFamily: "NotoSansKR-Bold", color: "white" }}
                  >
                    지원 및 문의
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{
                    border: "1px solid #333",
                    height: "80px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1em",
                  }}
                >
                  <Button
                    href="mailto:recruit@jindalrae.co.kr"
                    startIcon={<MailRoundedIcon />}
                    sx={{ color: "#414141", textTransform: "none" }}
                  >
                    <Typography variant="body1">
                      recruit@jindalrae.co.kr
                    </Typography>
                  </Button>
                  <Button
                    href="tel:0264293859"
                    startIcon={<LocalPhoneRoundedIcon />}
                    sx={{ color: "#414141" }}
                  >
                    <Typography variant="body1">02-6429-3859</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ width: "90%", m: "4em 0" }}>
              <Grid
                container
                columns={{ xs: 8, sm: 12, md: 24 }}
                rowSpacing={2}
              >
                {[
                  {
                    type: "ph",
                    img: "/images/talented/ic01.png",
                    text: "서류접수",
                  },
                  {
                    type: "ph",
                    img: "/images/talented/ic02.png",
                    text: "서류전형",
                  },
                  {
                    type: "ph",
                    img: "/images/talented/ic03.png",
                    text: "인터뷰",
                  },
                  {
                    type: "ph",
                    img: "/images/talented/ic04.png",
                    text: "고용심사",
                  },
                  {
                    type: "ph",
                    img: "/images/talented/ic05.png",
                    text: "처우협상",
                  },
                  {
                    type: "ph",
                    img: "/images/talented/ic06.png",
                    text: "최종합격",
                  },
                ].map((item, idx) => (
                  <Grid
                    key={idx}
                    item
                    xs={item.type === "ph" ? 4 : 1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {item.type === "ph" && (
                        <Paper
                          elevation={3}
                          sx={{
                            width: "120px",
                            height: "120px",
                            borderRadius: "50%",
                            border: "4px solid #bb4695",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box sx={{ textAlign: "center" }}>
                            <Box
                              component="img"
                              src={item.img}
                              sx={{ width: "60px", height: "auto" }}
                            />
                            <Typography variant="body2">{item.text}</Typography>
                          </Box>
                        </Paper>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ProcessComponent;
