import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const MissionComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      {/* 타이틀 */}
      <Box
        sx={{
          backgroundColor: "#f1f1ef",
          p: "2em 0",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{ color: "#212121", fontFamily: "NotoSansKR-Bold" }}
        >
          미션 스토리
        </Typography>
      </Box>
      {/* 미션스토리1 시작 */}
      <Box
        sx={{
          backgroundColor: "#f1f1ef",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/images/company/mission1.jpg"
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "center/cover",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ backgroundColor: "#f1f1ef" }}>
            <Box sx={{ backgroundColor: "#f1f1ef", padding: "1em 2em" }}>
              <Typography
                variant={enlarged ? "h4" : "h5"}
                sx={{
                  fontFamily: "NotoSansKR-Bold",
                  color: "#212121",
                  wordBreak: "keep-all",
                }}
              >
                고객 성공을 견인하는 전문가
              </Typography>
            </Box>
            <Box sx={{ backgroundColor: "#f1f1ef", padding: "1em 2em" }}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#212121",
                  wordBreak: "keep-all",
                }}
              >
                도전의식을 기반으로 끊임없이 새로운 기술의 전문성 확보하여
                고객의 비즈니스 과제를 해결하기 위한 기술 방안을 제공합니다.
                <br />
                <br />
                진달래소프트와 함께 AI, 빅데이터, 클라우드 등 다양한 신기술과
                기술 트렌드를 활용하여 새로운 시장과 고객을 확보하기 위한
                비즈니스 혁신에 도전하기 바랍니다.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* 미션스토리1 끝 */}
      {/* 미션스토리2 시작 */}
      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: "1em 2em" }}>
              <Typography
                variant={enlarged ? "h4" : "h5"}
                sx={{
                  fontFamily: "NotoSansKR-Bold",
                  color: "#212121",
                  wordBreak: "keep-all",
                }}
              >
                도전과 실패, 그리고 재도전
              </Typography>
            </Box>
            <Box sx={{ padding: "1em 2em" }}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#212121",
                  wordBreak: "keep-all",
                }}
              >
                내부의 실패는 고객의 성공을 위한 기반이 된다고 믿습니다.
                <br />
                실패 속에서도 끊임없는 재도전을 통해 한걸음 한걸음 씩
                전진하겠습니다. <br />
                <br />
                고객의 성공 속에는 진달래소프트의 도전 의식이 자리 할 것입니다.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/images/company/mission2.jpg"
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "center/cover",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {/* 미션스토리2 끝 */}
      {/* 미션스토리3 시작 */}
      <Box
        sx={{
          backgroundColor: "#fbfbf9",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/images/company/mission3.jpg"
              sx={{
                width: "100%",
                height: "auto",
                objectFit: "center/cover",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ backgroundColor: "#fbfbf9" }}>
            <Box sx={{ backgroundColor: "#fbfbf9", padding: "1em 2em" }}>
              <Typography
                variant={enlarged ? "h4" : "h5"}
                sx={{
                  fontFamily: "NotoSansKR-Bold",
                  color: "#212121",
                  wordBreak: "keep-all",
                }}
              >
                끝까지 해내는 열정
              </Typography>
            </Box>
            <Box sx={{ backgroundColor: "#fbfbf9", padding: "1em 2em" }}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#212121",
                  wordBreak: "keep-all",
                }}
              >
                진달래소프트는 당신의 뜨겁고, 지속적인 열정을 지지하고
                응원합니다.
                <br />
                언젠가 당신의 열정이 세상을 환하게 비추는 것을 믿습니다.
                <br />
                열정으로 고객의 앞길을 열어줄 것을 믿습니다.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* 미션스토리3 끝 */}
    </div>
  );
};

export default MissionComponent;
