import {
  ThemeProvider,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import React from "react";
import { defaultTheme } from "./misc/DefaultTheme";
import MenuBar from "./MenuBar";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const theme = useTheme();
  const enlarged = useMediaQuery(theme.breakpoints.up("md"));
  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <MenuBar
        sx={{ zIndex: 100 }}
        enlarged={enlarged}
        scrolled={scrolled}
      ></MenuBar>
      <Outlet context={[enlarged, scrolled]} />
    </ThemeProvider>
  );
};

export default Layout;
