import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const StoryComponent = ({ enlarged }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#efefef",
      }}
    >
      <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ m: "2em 0" }}>
            <Typography
              variant="h4"
              sx={{ color: "#212121", fontFamily: "NotoSansKR-Bold" }}
            >
              성공 스토리
            </Typography>
          </Box>
        </Grid>
        {/* 첫번째 Story 시작 */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="/images/main03.jpg"
            sx={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              textAlign: "center",
              mb: "2em",
            }}
          >
            <Box>
              <Box
                sx={{
                  padding: "1em 2em",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR-Bold",
                    color: "#212121",
                    p: enlarged ? "1em" : 0,
                    wordBreak: "keep-all",
                  }}
                  variant={enlarged ? "h4" : "h5"}
                >
                  효과적인 K社 차세대 시스템 구축을 위해 29종의 오픈소스
                  소프트웨어를 전략적으로 활용하였습니다.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ padding: "1em 2em" }}>
              <Typography
                variant={enlarged ? "h6" : "body1"}
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#424242",
                  wordBreak: "keep-all",
                }}
              >
                어플리케이션 형상관리, 배포, 연계프로그램, 개발플랫폼 등 29종의
                오픈소스를 사용하여, 다양한 현업의 업무 기능을 구현하였습니다.
                오픈소스 적용에 따라 구축 비용을 절감하였고, 제품 도입 절차를
                간소화하고 구현 기간도 단축시킬 수 있었습니다.
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* 첫번째 Story 끝 */}
        {/* 두번째 Story 시작 */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              textAlign: "center",
              borderTop: enlarged ? "" : "1px dashed #414141",
              mb: "2em",
            }}
          >
            <Box>
              <Box
                sx={{
                  padding: "1em 2em",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR-Bold",
                    color: "#212121",
                    p: enlarged ? "1em" : 0,
                    wordBreak: "keep-all",
                  }}
                  variant={enlarged ? "h4" : "h5"}
                >
                  L社 디지털 비즈니스 전환을 효율적으로 지원하도록 CI/CD를
                  구축하였습니다.
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "1em 2em",
                }}
              >
                <Typography
                  variant={enlarged ? "h6" : "body1"}
                  sx={{
                    fontFamily: "NotoSansKR-Medium",
                    color: "#424242",
                    wordBreak: "keep-all",
                  }}
                >
                  진달래소프트는 오픈소스 기반의 CI/CD를 구축하여 고객의
                  어플리케이션 배포 프로세스를 자동화하고 더 나은 품질의
                  어플리케이션 서비스를 제공할 수 있도록 지원하였습니다.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {enlarged && (
          <Grid item xs={12} md={6}>
            <Box>
              <Box
                component="img"
                src="/images/main04.jpg"
                sx={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
        )}
        {/* 두번째 Story 끝 */}
      </Grid>
    </Box>
  );
};

export default StoryComponent;
