import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import Bottom from "../Bottom";

import IntroComponent from "../../components/home/IntroComponent";
import { useOutletContext } from "react-router-dom/dist";
import OssComponent from "../../components/home/OssComponent";
import DigitalInnoComponent from "../../components/home/DigitalInnoComponent";
import StoryComponent from "../../components/home/StoryComponent";
import RisingComponent from "../../components/home/RisingComponent";
import ScrollToAnchor from "../../components/misc/ScrollToAnchor";
import SpeedDialComponent from "../../components/misc/SpeedDialComponent";
import PortfolioComponent from "../../components/business/PortfolioComponent";
import ConsultingComponent from "../../components/business/ConsultingComponent";
import InfraComponent from "../../components/business/InfraComponent";
import SolutionsComponent from "../../components/business/SolutionsComponent";

const Home = () => {
  const [containerHeight, setContainerHeight] = useState("0");
  const [enlarged, scrolled] = useOutletContext();

  useEffect(() => {
    setContainerHeight(`${window.innerHeight}px`);
  }, []);

  return (
    <Box id="top" sx={{ position: "relative" }}>
      <Grid
        container
        sx={{ position: "absolute", top: "0px" }}
        spacing={0}
        gap={0}
      >
        <Grid item xs={12}>
          <IntroComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12}>
          <OssComponent containerHeight={containerHeight} enlarged={enlarged} />
        </Grid>
        <Grid item xs={12}>
          <DigitalInnoComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12}>
          <StoryComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12} id="portfolio">
          <PortfolioComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>

        <Grid item xs={12} id="consulting">
          <ConsultingComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>

        <Grid item xs={12} id="infra">
          <InfraComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12} id="solution">
          <SolutionsComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>

        <Grid item xs={12}>
          <RisingComponent
            containerHeight={containerHeight}
            enlarged={enlarged}
          />
        </Grid>
        <Grid item xs={12}>
          <Bottom />
        </Grid>
      </Grid>
      <ScrollToAnchor />
      {!enlarged && scrolled && <SpeedDialComponent />}
    </Box>
  );
};

export default Home;
