import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

const PortfolioComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={{ m: "2em 0", width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR-Bold",
                color: "#414141",
                wordBreak: "keep-all",
              }}
              variant="h4"
            >
              서비스 포트폴리오
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", padding: "2em" }}
          >
            <Typography
              sx={{
                fontFamily: "NotoSansKR-Medium",
                color: "#414141",
                wordBreak: "keep-all",
              }}
              variant={enlarged ? "h5" : "h6"}
            >
              최고의 정보시스템 구축을 위해 최적의 컨설팅, 구축 서비스, 솔루션을
              제공합니다.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ padding: "2em" }}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {/* BOX1 시작 */}
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    width: "90%",
                    backgroundColor: "#ef5350",
                    borderRadius: "15px 0 15px 0",
                    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                  }}
                >
                  <Box sx={{ textAlign: "center", padding: "1em 0" }}>
                    <Box component="img" src="/images/business/ico01.png" />
                    <Typography
                      variant="h6"
                      sx={{ color: "white", fontFamily: "NotoSansKR-Bold" }}
                    >
                      IT 컨설팅
                    </Typography>
                  </Box>
                  <Box sx={{ padding: "1em 0" }}>
                    {[
                      "정보 전략 수립",
                      "기간업무/채널/정보분석 시스템 아키텍처 설계",
                      "클라우드 전환 전략 수립",
                    ].map((item, idx) => (
                      <Stack key={idx} direction="row">
                        <KeyboardArrowRightRoundedIcon
                          sx={{ color: "white" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "white", wordBreak: "keep-all" }}
                        >
                          {item}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                </Box>
              </Grid>
              {/* BOX1 끝 */}
              {/* BOX2 시작 */}
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    width: "90%",
                    backgroundColor: "#ba68c8",
                    borderRadius: "15px 0 15px 0",
                    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                  }}
                >
                  <Box sx={{ textAlign: "center", padding: "1em 0" }}>
                    <Box component="img" src="/images/business/ico02.png" />
                    <Typography
                      variant="h6"
                      sx={{ color: "white", fontFamily: "NotoSansKR-Bold" }}
                    >
                      IT시스템 구축
                    </Typography>
                  </Box>
                  <Box sx={{ padding: "1em 0" }}>
                    {[
                      "클라우드 IaaS, PaaS 인프라 구축",
                      "IT시스템 성능 진단 및 개선",
                      "CI/CD 인프라 구축",
                    ].map((item, idx) => (
                      <Stack key={idx} direction="row">
                        <KeyboardArrowRightRoundedIcon
                          sx={{ color: "white" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "white", wordBreak: "keep-all" }}
                        >
                          {item}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                </Box>
              </Grid>
              {/* BOX2 끝 */}
              {/* BOX3 시작 */}
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    width: "90%",
                    backgroundColor: "#42a5f5",
                    borderRadius: "15px 0 15px 0",
                    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                  }}
                >
                  <Box sx={{ textAlign: "center", padding: "1em 0" }}>
                    <Box component="img" src="/images/business/ico03.png" />
                    <Typography
                      variant="h6"
                      sx={{ color: "white", fontFamily: "NotoSansKR-Bold" }}
                    >
                      IT솔루션 공급
                    </Typography>
                  </Box>
                  <Box sx={{ padding: "1em 0" }}>
                    {["APPSPINE", "Azul Systems", "오픈소스 솔루션"].map(
                      (item, idx) => (
                        <Stack key={idx} direction="row">
                          <KeyboardArrowRightRoundedIcon
                            sx={{ color: "white" }}
                          />
                          <Typography
                            variant="body2"
                            sx={{ color: "white", wordBreak: "keep-all" }}
                          >
                            {item}
                          </Typography>
                        </Stack>
                      )
                    )}
                  </Box>
                </Box>
              </Grid>
              {/* BOX3 끝 */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PortfolioComponent;
