import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

const OssComponent = ({ enlarged }) => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              position: "relative",
              backgroundImage: "url(/images/main01.png)",
              backgroundSize: "cover",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: enlarged ? "80%" : "90%",
                textAlign: "center",
                padding: "2em 1em",
              }}
            >
              <Typography
                variant={enlarged ? "h4" : "h5"}
                sx={{ fontFamily: "NotoSansKR-Bold", wordbreak: "keep-all" }}
              >
                디지털 혁신의 성공과 실패는 <br />
                오픈소스 활용에 달려있습니다.
              </Typography>
              <Typography
                varaint="h6"
                sx={{
                  fontFamily: "NotoSAnsKR-Medium",
                  color: "#616161",
                  mt: "1.5em",
                  wordbreak: "keep-all",
                }}
              >
                디지털 비즈니스 혁신의 성공은 오픈소스 소프트웨어를 얼마나 잘
                활용하느냐에 따라 좌우됩니다.
              </Typography>
            </Box>
            <Box
              sx={{
                width: enlarged ? "80%" : "100%",
                textAlign: "center",
              }}
            >
              <Box
                sx={{ display: "flex", justifyContent: "center", mb: "2em" }}
                gap={enlarged ? 3 : 1}
              >
                <Card
                  sx={{ padding: "0.5em 0", width: enlarged ? "30%" : "45%" }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography
                      variant="h4"
                      color="primary"
                      sx={{ fontFamily: "NotoSansKR-Bold" }}
                    >
                      7조원
                    </Typography>
                  </CardContent>
                  <CardHeader
                    title="오픈소스 시장가치"
                    titleTypographyProps={{
                      variant: enlarged ? "body1" : "body2",
                      fontFamily: "NotoSansKR-Medium",
                      wordbreak: "keep-all",
                    }}
                  ></CardHeader>
                </Card>
                <Card
                  sx={{ padding: "0.5em 0", width: enlarged ? "30%" : "45%" }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography
                      variant="h4"
                      color="primary"
                      sx={{ fontFamily: "NotoSansKR-Bold" }}
                    >
                      61.5%
                    </Typography>
                  </CardContent>
                  <CardHeader
                    title="오픈소스 활용율"
                    titleTypographyProps={{
                      variant: enlarged ? "body1" : "body2",
                      fontFamily: "NotoSansKR-Medium",
                      wordbreak: "keep-all",
                    }}
                  ></CardHeader>
                </Card>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default OssComponent;
