import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import { purple } from "@mui/material/colors";

const ContactComponent = ({ enlarged }) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#f6f6f6",
        }}
      >
        <Grid
          container
          sx={{ padding: "2em 1em", width: enlarged ? "80%" : "100%" }}
        >
          {/*타이틀*/}
          <Grid item xs={12}>
            <Box sx={{ mb: "1em", textAlign: "center" }}>
              <Typography
                variant="h4"
                sx={{ color: "#212121", fontFamily: "NotoSansKR-Bold" }}
              >
                연락처
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={2}>
                {/* 회사 이메일 */}
                <Grid item xs={12} md={6}>
                  <Stack
                    direction="row"
                    sx={{ mb: "0.5em", alignItems: "center" }}
                  >
                    <ContactMailOutlinedIcon
                      sx={{ mr: "0.5em", color: purple[800] }}
                      fontSize="small"
                    />
                    <Typography variant="body1" sx={{ color: purple[800] }}>
                      이메일 문의
                    </Typography>
                  </Stack>
                  <Grid
                    container
                    sx={{
                      padding: "1px",
                      borderTop: "3px solid #bb4695",
                    }}
                  >
                    <Grid
                      item
                      xs={5}
                      sx={{
                        backgroundColor: "#efefef",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid #ddd",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "NotoSansKR-Bold", color: "#212121" }}
                      >
                        업무관련
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{
                        backgroundColor: "#fff",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #ddd",
                        paddingLeft: "0.5em",
                      }}
                    >
                      <Button
                        href="mailto:partner@jindalrae.co.kr"
                        sx={{ color: "#212121", textTransform: "none", p: 0 }}
                      >
                        <Typography variant="body2" sx={{ color: "#212121" }}>
                          partner@jindalrae.co.kr
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sx={{
                        backgroundColor: "#efefef",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid #ddd",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "NotoSansKR-Bold" }}
                      >
                        결제/계산서 관련
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{
                        backgroundColor: "#fff",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "0.5em",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <Button
                        href="mailto:bill@jindalrae.co.kr"
                        sx={{ color: "#212121", textTransform: "none", p: 0 }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontFamily: "NotoSansKR-Medium" }}
                        >
                          bill@jindalrae.co.kr
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {/* 회사 이메일 끝 */}
                {/* 전화 및 팩스 */}
                <Grid item xs={12} md={6}>
                  <Stack
                    direction="row"
                    sx={{ mb: "0.5em", alignItems: "center" }}
                  >
                    <ContactPhoneOutlinedIcon
                      sx={{ mr: "0.5em", color: purple[800] }}
                      fontSize="small"
                    />
                    <Typography variant="body1" sx={{ color: purple[800] }}>
                      전화 문의
                    </Typography>
                  </Stack>
                  <Grid
                    container
                    sx={{
                      padding: "1px",
                      borderTop: "3px solid #bb4695",
                    }}
                  >
                    <Grid
                      item
                      xs={5}
                      sx={{
                        backgroundColor: "#efefef",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid #ddd",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "NotoSansKR-Bold", color: "#212121" }}
                      >
                        전화번호
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{
                        backgroundColor: "#fff",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #ddd",
                        paddingLeft: "0.5em",
                      }}
                    >
                      {enlarged ? (
                        <Typography variant="body2" sx={{ color: "#212121" }}>
                          02-6429-3859
                        </Typography>
                      ) : (
                        <Button
                          href="tel:0264293859"
                          sx={{ color: "#212121", textTransform: "none", p: 0 }}
                        >
                          <Typography variant="body2" sx={{ color: "#212121" }}>
                            02-6429-3859
                          </Typography>
                        </Button>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sx={{
                        backgroundColor: "#efefef",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid #ddd",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "NotoSansKR-Bold" }}
                      >
                        팩스번호
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{
                        backgroundColor: "#fff",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "0.5em",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: "NotoSansKR-Medium" }}
                      >
                        02-6499-3859
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* 전화 및 팩스 끝 */}

                <Grid item xs={12} md={4}></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ContactComponent;
