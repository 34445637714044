import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const YH = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ position: "relative" }}>
            <Grid container sx={{ mt: "50px" }}>
              <Grid item xs={12} md={5} sx={{ padding: "5% 5%" }}>
                <Typography
                  variant={enlarged ? "h4" : "h6"}
                  sx={{
                    fontFamily: "NotoSansKR-Bold",
                    color: "#bb4695",
                    wordBreak: "keep-all",
                  }}
                >
                  “고객의 비즈니스 성공에 기여하는 신뢰받는 파트너가 되겠습니다”
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  textAlign: "right",
                }}
              >
                <Box
                  component="img"
                  src="/images/company/whowe01.jpg"
                  sx={{
                    width: enlarged ? "350px" : "150px",
                    height: "auto",
                    marginRight: 0,
                  }}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",
                bottom: "50px",
                right: "40%",
                backgroundColor: "transparent",
                textAlign: "right",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontFamily: "NotoSansKR-Bold", mb: "1em" }}
              >
                이용한 공동대표
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#757575",
                  mb: "0.3em",
                }}
              >
                라이나생명 아키텍트 리더
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#757575",
                  mb: enlarged ? "0.3em" : 0,
                }}
              >
                IBM GBS 컨설턴트
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#757575",
                  mb: enlarged ? "0.3em" : 0,
                }}
              >
                SK C&C R&D 리더
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "NotoSansKR-Medium",
                  color: "#757575",
                  mb: enlarged ? "0.3em" : 0,
                }}
              >
                아키텍트, 프레임워크 전문가
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default YH;
