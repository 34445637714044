import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";

import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";

const CIComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={{ mb: "2em", width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12}>
            <Box
              sx={{
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant={enlarged ? "h3" : "h5"}
                sx={{ fontFamily: "NotoSansKR-Bold" }}
              >
                CI
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            {/* 그리드 시작 */}
            <Grid item xs={12} md={6}>
              <Box sx={{ paddingLeft: "2em", mt: "1em" }}>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <ArrowRightRoundedIcon size="large" />
                  <Typography
                    variant={enlarged ? "h5" : "h6"}
                    sx={{ fontFamily: "NotoSansKR-Bold" }}
                  >
                    그리드
                  </Typography>
                </Stack>
                <Box>
                  <Paper
                    sx={{
                      width: "80%",
                      padding: "1em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "url('/images/company/grid.png')",
                    }}
                    variant="outlined"
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          component="img"
                          src="/images/company/2-1.png"
                          sx={{ width: "200px", height: "auto" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          component="img"
                          src="/images/company/2-2.png"
                          sx={{ width: "200px", height: "auto" }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </Box>
            </Grid>
            {/* 그리드 끝 */}
            {/* 한영혼용 시작 */}
            <Grid item xs={12} md={6}>
              <Box sx={{ paddingLeft: "2em", mt: "1em" }}>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <ArrowRightRoundedIcon size="large" />
                  <Typography
                    variant={enlarged ? "h5" : "h6"}
                    sx={{ fontFamily: "NotoSansKR-Bold" }}
                  >
                    한영혼용
                  </Typography>
                </Stack>
                <Box>
                  <Paper
                    sx={{
                      width: "80%",
                      padding: "1em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    variant="outlined"
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          component="img"
                          src="/images/company/2-1.png"
                          sx={{ width: "200px", height: "auto" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          component="img"
                          src="/images/company/2-2.png"
                          sx={{ width: "200px", height: "auto" }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </Box>
            </Grid>
            {/* 한영혼용 끝 */}
            {/* 한글 시작 */}
            <Grid item xs={12} md={6}>
              <Box sx={{ paddingLeft: "2em", mt: "1em" }}>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <ArrowRightRoundedIcon size="large" />
                  <Typography
                    variant={enlarged ? "h5" : "h6"}
                    sx={{ fontFamily: "NotoSansKR-Bold" }}
                  >
                    한글
                  </Typography>
                </Stack>
                <Box>
                  <Paper
                    sx={{
                      width: "80%",
                      padding: "1em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    variant="outlined"
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          component="img"
                          src="/images/company/2-3.png"
                          sx={{ width: "200px", height: "auto" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          component="img"
                          src="/images/company/2-4.png"
                          sx={{ width: "200px", height: "auto" }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </Box>
            </Grid>
            {/* 한글 끝 */}
            {/* 영문 시작 */}
            <Grid item xs={12} md={6}>
              <Box sx={{ paddingLeft: "2em", mt: "1em" }}>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <ArrowRightRoundedIcon size="large" />
                  <Typography
                    variant={enlarged ? "h5" : "h6"}
                    sx={{ fontFamily: "NotoSansKR-Bold" }}
                  >
                    영문
                  </Typography>
                </Stack>
                <Box>
                  <Paper
                    sx={{
                      width: "80%",
                      padding: "1em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    variant="outlined"
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          component="img"
                          src="/images/company/2-5.png"
                          sx={{ width: "200px", height: "auto" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          component="img"
                          src="/images/company/2-6.png"
                          sx={{ width: "200px", height: "auto" }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </Box>
            </Grid>
            {/* 영문 끝 */}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CIComponent;
