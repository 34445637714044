import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const BenefitsComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Grid container sx={{ backgroundColor: "#bb4695", padding: "2em 1em" }}>
        <Grid item xs={12}>
          <Box sx={{ textAlign: "center", m: "2em 0" }}>
            <Typography
              variant="h4"
              sx={{ fontFamily: "NotoSansKR-Bold", color: "white" }}
            >
              복리후생
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: enlarged ? "80%" : "90%" }}>
            <Grid
              container
              columns={{ xs: 12, md: 20 }}
              spacing={{ xs: 1, md: 2 }}
            >
              {[
                {
                  img: "/images/talented/p01.png",
                  text: "건강검진",
                },
                {
                  img: "/images/talented/p02.png",
                  text: "퇴직연금",
                },
                {
                  img: "/images/talented/p03.png",
                  text: "유연근무제",
                },
                { img: "/images/talented/p04.png", text: "성과급" },
                {
                  img: "/images/talented/p05.png",
                  text: "근속장려금",
                },
                {
                  img: "/images/talented/p06.png",
                  text: "통근수당",
                },
                {
                  img: "/images/talented/p07.png",
                  text: "중식수당",
                },
                {
                  img: "/images/talented/p08.png",
                  text: "경조사비",
                },
                {
                  img: "/images/talented/p09.png",
                  text: "육아휴직",
                },
              ].map((item, idx) => (
                <Grid key={idx} item xs={4} md={4}>
                  <Paper sx={{ padding: "1em 0", textAlign: "center" }}>
                    <Box
                      component="img"
                      src={item.img}
                      sx={{ width: "70px", height: "auto" }}
                    />
                    <Typography variant="body2">{item.text}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default BenefitsComponent;
