import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";

const OccuComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#f6f6f6",
        }}
      >
        <Grid
          container
          sx={{ padding: "2em 1em", width: enlarged ? "80%" : "100%" }}
        >
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center", m: "2em 0" }}>
              <Typography variant="h4" sx={{ fontFamily: "NotoSansKR-Bold" }}>
                채용직군
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Grid container sx={{ borderTop: "3px solid #bb4695" }}>
                {/* 타이틀 */}
                <Grid
                  item
                  xs={3}
                  sx={{
                    backgroundColor: "#eee",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "NotoSansKR-Bold", color: "#212121" }}
                  >
                    직군
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    backgroundColor: "#eee",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "NotoSansKR-Bold", color: "#212121" }}
                  >
                    담당업무
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    backgroundColor: "#eee",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "NotoSansKR-Bold", color: "#212121" }}
                  >
                    조건
                  </Typography>
                </Grid>

                {/* 아키텍트 */}
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                    padding: "0.5em 0 0.5em 0.5em",
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography variant="body2" sx={{ color: "#212121" }}>
                    IT아키텍트 <br />& 컨설턴트
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                    padding: "0.5em 0 0.5em 0",
                    backgroundColor: "#fff",
                  }}
                >
                  {[
                    "영업 지원 및 제안 활동",
                    "IT컨설팅 수행",
                    "IT 시스템 설계 및 구축",
                  ].map((item, idx) => (
                    <Stack key={idx} direction="row">
                      {enlarged && (
                        <ArrowRightRoundedIcon sx={{ color: "#212121" }} />
                      )}
                      <Typography
                        variant="body2"
                        sx={{ color: "#212121", ml: "0.2em" }}
                      >
                        {item}
                      </Typography>
                    </Stack>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                    padding: "0.5em 0 0.5em 0",
                    backgroundColor: "#fff",
                  }}
                >
                  {[
                    "유관 업무 경력 5년 이상",
                    "문서 작성 역량",
                    "금융 사업 유경험자 우대",
                    "클라우드 유경험자 우대",
                  ].map((item, idx) => (
                    <Stack key={idx} direction="row">
                      {enlarged && (
                        <ArrowRightRoundedIcon sx={{ color: "#212121" }} />
                      )}
                      <Typography
                        variant="body2"
                        sx={{ color: "#212121", ml: "0.2em" }}
                      >
                        {item}
                      </Typography>
                    </Stack>
                  ))}
                </Grid>
                {/* 개발자 */}
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                    padding: "0.5em 0 0.5em 0.5em",
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography variant="body2" sx={{ color: "#212121" }}>
                    어플리케이션 개발
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                    padding: "0.5em 0 0.5em 0",
                    backgroundColor: "#fff",
                  }}
                >
                  {[
                    "Front-End, Back-End 개발",
                    "IT시스템 운영",
                    "자체 솔루션 개발 및 운영",
                    "제품 기술 지원",
                  ].map((item, idx) => (
                    <Stack key={idx} direction="row">
                      {enlarged && (
                        <ArrowRightRoundedIcon sx={{ color: "#212121" }} />
                      )}
                      <Typography
                        variant="body2"
                        sx={{ color: "#212121", ml: "0.2em" }}
                      >
                        {item}
                      </Typography>
                    </Stack>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                    padding: "0.5em 0 0.5em 0",
                    backgroundColor: "#fff",
                  }}
                >
                  {[
                    "Spring 프레임워크 활용 기술",
                    "Java, JSP, HTML5, SQL",
                    "Linux, Windows 서버 경험",
                  ].map((item, idx) => (
                    <Stack key={idx} direction="row">
                      {enlarged && (
                        <ArrowRightRoundedIcon sx={{ color: "#212121" }} />
                      )}
                      <Typography
                        variant="body2"
                        sx={{ color: "#212121", ml: "0.2em" }}
                      >
                        {item}
                      </Typography>
                    </Stack>
                  ))}
                </Grid>
                {/* 영업 */}
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                    padding: "0.5em 0 0.5em 0.5em",
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography variant="body2" sx={{ color: "#212121" }}>
                    영업
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                    padding: "0.5em 0 0.5em 0",
                    backgroundColor: "#fff",
                  }}
                >
                  {["솔루션 및 인프라 영업", "Public/Private Cloud 영업"].map(
                    (item, idx) => (
                      <Stack key={idx} direction="row">
                        {enlarged && (
                          <ArrowRightRoundedIcon sx={{ color: "#212121" }} />
                        )}
                        <Typography
                          variant="body2"
                          sx={{ color: "#212121", ml: "0.2em" }}
                        >
                          {item}
                        </Typography>
                      </Stack>
                    )
                  )}
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderTop: "1px solid #ddd",
                    borderBottom: "1px solid #ddd",
                    padding: "0.5em 0 0.5em 0",
                    backgroundColor: "#fff",
                  }}
                >
                  {[
                    "유관 업무 경력 5년 이상",
                    "대인 영업 경험 필수",
                    "금융, 공공, 제조 산업 이해",
                  ].map((item, idx) => (
                    <Stack key={idx} direction="row">
                      {enlarged && (
                        <ArrowRightRoundedIcon sx={{ color: "#212121" }} />
                      )}
                      <Typography
                        variant="body2"
                        sx={{ color: "#212121", mr: "0.2em" }}
                      >
                        {item}
                      </Typography>
                    </Stack>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default OccuComponent;
