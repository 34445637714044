import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import YH from "./YH";
import JY from "./JY";
import YJ from "./YJ";
import WM from "./WM";
import ScrollToAnchor from "../misc/ScrollToAnchor";

const MembersComponent = ({ containerHeight, enlarged, direct }) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#d6d9de",
        }}
      >
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12}>
            <Box
              sx={{
                position: "relative",
                backgroundColor: "#d6d9de",
                width: "100%",
                textAlign: "center",
              }}
            >
              <Box
                component="img"
                src="/images/company/whowe.jpg"
                sx={{ width: "100%", height: "height" }}
              />
              {!direct && (
                <Box
                  sx={{
                    position: "absolute",
                    top: enlarged ? "12%" : "8%",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "NotoSansKR-Bold",
                      color: "#212121",
                    }}
                  >
                    멤버 소개
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* 이용한 */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
        id="yhlee"
      >
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12}>
            <YH containerHeight={containerHeight} enlarged={enlarged} />
          </Grid>
        </Grid>
      </Box>

      {/* 전진영 */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#d3d8de",
        }}
        id="jay.jeon"
      >
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12}>
            <JY containerHeight={containerHeight} enlarged={enlarged} />
          </Grid>
        </Grid>
      </Box>

      {/* 양원모 */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#fff",
        }}
        id="wonmo.yang"
      >
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12}>
            <WM containerHeight={containerHeight} enlarged={enlarged} />
          </Grid>
        </Grid>
      </Box>

      {/* 김영준 */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#d3d8de",
        }}
        id="kimyj"
      >
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12}>
            <YJ containerHeight={containerHeight} enlarged={enlarged} />
          </Grid>
        </Grid>
      </Box>
      <ScrollToAnchor />
    </div>
  );
};

export default MembersComponent;
