import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const LetsgoComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={{ width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12} md={5} sx={{ padding: "1em" }}>
            <Box>
              <Box
                component="img"
                src="/images/talented/people01.jpg"
                sx={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              padding: "1em",
            }}
          >
            <Box
              sx={{ mt: enlarged ? "3em" : 0, mb: "1em", textAlign: "center" }}
            >
              <Typography
                variant="h4"
                sx={{ fontFamily: "CantoraOne-Regular", color: "#212121" }}
              >
                Let's Go Together
              </Typography>
            </Box>
            <Box sx={{ padding: "2em" }}>
              <Typography
                variant="body1"
                sx={{ color: "#212121", wordBreak: "keep-all" }}
              >
                진달래소프트가 도전하는 당신을 기다립니다.
                <br />
                진달래소프트에는 실패자가 없습니다. 왜냐하면 성공할 때까지 다시
                도전하기 때문입니다.
                <br />
                진달래소프트는 당신이 도전하고, 성공할 때까지 함께하겠습니다.
                <br />
                같이 도전하고, 같이 성공하고, 같이 행복해지는 진달래소프트가
                당신을 응원합니다.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default LetsgoComponent;
