import React from "react";

import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { purple } from "@mui/material/colors";

const InfraComponent = ({ containerHeight, enlarged }) => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={{ m: "2em 0", width: enlarged ? "80%" : "100%" }}>
          <Grid item xs={12}>
            <Box sx={{ p: "2em 0", textAlign: "center" }}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "NotoSansKR-Bold", color: "#212121" }}
              >
                IT 시스템 구축
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: "1em 1em" }}>
              <Box sx={{ textAlign: "center", mb: "1em", padding: "1em 0" }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: "1em",
                    color: "#414141",
                    fontFamily: "NotoSansKR-Bold",
                    wordBreak: "keep-all",
                  }}
                >
                  최고의 경험과 역량을
                  <br /> 기반으로 최적의 IT시스템을 구축 합니다.
                </Typography>
                <Box component="img" src="/images/business/system02.png" />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box>
                  <Stack
                    direction="row"
                    sx={{ alignItems: "center", mb: "0.5em" }}
                  >
                    <EventNoteRoundedIcon
                      sx={{ color: purple[800], mr: "0.3em" }}
                    />
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: "NotoSansKR-Bold", color: purple[800] }}
                    >
                      IT시스템 구축 세부 영역
                    </Typography>
                  </Stack>
                  {[
                    "어플리케이션, 인프라 시스템 구축",
                    "클라우드 기반 IaaS, PaaS 플랫폼 구축",
                    "IT시스템 성능 진단 및 개선",
                    "CI/CD 인프라 구축",
                  ].map((item, idx) => (
                    <Stack key={idx} direction="row">
                      <KeyboardArrowRightRoundedIcon
                        sx={{ color: "#414141", m: "0 0.5em" }}
                      />
                      <Typography
                        variant="body1"
                        sx={{ color: "#414141", wordBreak: "keep-all" }}
                      >
                        {item}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
          {enlarged && (
            <Grid item md={6}>
              <Box>
                <Box
                  component="img"
                  src="/images/company/history.jpg"
                  sx={{
                    width: "100%",
                    height: enlarged ? "500px" : "300px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default InfraComponent;
