import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const RisingComponent = ({ enlarged }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            backgroundImage: "url(/images/main05.jpg)",
            backgroundSize: "cover",
            width: "100%",
            p: "2em 0",
          }}
        >
          <Box
            sx={{
              width: "90%",
              textAlign: "center",
              padding: "0px 10px",
            }}
          >
            <Typography
              variant={enlarged ? "h4" : "h5"}
              sx={{
                fontFamily: "NotoSansKR-Bold",
                color: "#efefef",
                wordBreak: "keep-all",
              }}
            >
              서울 4대 미래성장산업 <br /> 유망선도기업, 진달래소프트
            </Typography>
            <Typography
              varaint="h6"
              sx={{
                fontFamily: "NotoSAnsKR-Medium",
                color: "#efefef",
                margin: "2em 0.5em",
                wordBreak: "keep-all",
              }}
            >
              진달래소프트는 서울시와 중소벤처기업진흥공단이 지원하는 '23년도
              「서울 4대 미래성장산업 유망선도기업」으로 선정되었습니다.
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }} gap={2}>
              <Paper>
                <Box
                  component="img"
                  src="/images/p01.png"
                  sx={{ width: "150px", height: "auto", mt: "10px" }}
                />
              </Paper>
              <Paper>
                <Box
                  component="img"
                  src="/images/p02.png"
                  sx={{ width: "150px", height: "auto", mt: "10px" }}
                />
              </Paper>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RisingComponent;
