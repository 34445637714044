import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { menuRouter } from "./route/MenuRouter";

function App() {
  return (
    <RouterProvider router={createBrowserRouter(menuRouter)}></RouterProvider>
  );
}

export default App;
