import { Box, Typography } from "@mui/material";
import React from "react";

const Bottom = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#212121",
        alignItems: "center",
        textAlign: "center",
        p: "1em 0",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: "White",
          fontFamily: "GmarketSans-Medium",
          wordBreak: "keep-all",
        }}
      >
        Copyright ⓒ 2024 진달래소프트 (주). All rights reserved.
      </Typography>
    </Box>
  );
};

export default Bottom;
