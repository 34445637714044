import React from "react";
import Layout from "../pages/Layout";
import NotFound from "../pages/misc/NotFound";
import Home from "../pages/contents/Home";
import Company from "../pages/contents/Company";
import Business from "../pages/contents/Business";
import Talented from "../pages/contents/Talented";
import Contact from "../pages/contents/Contact";
import Iam from "../pages/misc/Iam";
import MembersOnly from "../pages/contents/MembersOnly";

export const menuRouter = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Home />,
        title: "홈",
      },
      {
        path: "/company",
        element: <Company />,
        title: "회사소개",
      },
      {
        path: "/business",
        element: <Business />,
        title: "사업영역",
      },
      {
        path: "/talented",
        element: <Talented />,
        title: "인재영입",
      },
      {
        path: "/contact",
        element: <Contact />,
        title: "업무문의",
      },
      {
        path: "/members",
        element: <MembersOnly />,
        title: "멤버소개",
      },
      { path: "/iam/:id", element: <Iam />, title: "Go멤버상세소개" },
    ],
  },
];

const MenuRouter = () => {
  return <div></div>;
};

export default MenuRouter;
