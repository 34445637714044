import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Iam = () => {
  const { id } = useParams();
  const navi = useNavigate();

  useEffect(() => {
    id && navi(`/members#${id}`);
  }, [id, navi]);

  return null;
};

export default Iam;
